import styled from 'styled-components';
import { GOLD_COLOR, PRIMARY_COLOR, PRIMARY_TEXT_COLOR } from '../../constants';
import { desktopMediaQuery } from '../../styleHelpers';
import { comeUpAnimation } from '../../components/ImageStuff/animations';

const TitlesSection = () => (
  <ContainerForPageTitles>
    <Title />
    <SubTitle />
  </ContainerForPageTitles>
);

const ContainerForPageTitles = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  max-width: 600px;
  margin-top: 48px;
  margin-bottom: 8px;
`;

const Title = () => (
  <TitleContainer>Browse and create AI Nudes</TitleContainer>
);

const TitleContainer = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;

  ${desktopMediaQuery} {
    font-size: 50px;
    line-height: 62px;
  }

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    to right,
    ${GOLD_COLOR} 20%,
    ${PRIMARY_COLOR} 80%
  );
  margin-bottom: 8px;

  animation: ${comeUpAnimation} 0.8s ease-out forwards;
  opacity: 0; // Start invisible
`;

const SubTitle = () => (
  <SubTitleContainer>
    Join 500k+ users on the best NSFW AI Community. Browse, edit, and
    generate with the nude image generator by OnlyFakes app
  </SubTitleContainer>
);

const SubTitleContainer = styled.h2`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
  color: ${PRIMARY_TEXT_COLOR};

  align-self: center;

  ${desktopMediaQuery} {
    font-size: 24px;
  }
`;

export default TitlesSection;
