import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MoreInfoNotificationModal from './MoreInfoNotificationModal';
import { useLoggedInUserContext } from '../../../context/LoggedInUserContextProvider';
import { desktopMediaQuery } from '../../../styleHelpers';

const text =
  "Credit payments are online on the new site UncensoredAI.io. Click the link to visit the new site and buy credits. You can use your current login credentials to log in.";
const SHOW_MORE_INFO_BUTTON = true;
const AUTO_HIDE_NOTIFICATION_AFTER_DATE = new Date('2025-12-28');

const NotificationBar = () => {
  const { loggedInUser } = useLoggedInUserContext();
  const [showNotification, setShowNotification] = useState(true);
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false);

  useEffect(() => {
    if (new Date() > AUTO_HIDE_NOTIFICATION_AFTER_DATE) {
      setShowNotification(false);
      return;
    }

    if (!loggedInUser) {
      setShowNotification(false);
      return;
    }
    
    setShowNotification(true);
  }, [loggedInUser]);

  const handleClose = () => {
    setShowNotification(false);
  };

  const handleMoreInfo = () => {
    window.location.href = 'https://www.uncensoredai.io';
  };

  if (!showNotification) return null;

  return (
    <>
      <NotificationBarContainer>
        <NotificationText>{text}</NotificationText>
        <ButtonContainer>
          {SHOW_MORE_INFO_BUTTON && (
            <MoreInfoButton onClick={handleMoreInfo}>Visit</MoreInfoButton>
          )}
          <CloseButton onClick={handleClose}>X</CloseButton>
        </ButtonContainer>
      </NotificationBarContainer>
      <MoreInfoNotificationModal
        show={showMoreInfoModal}
        setShow={setShowMoreInfoModal}
      />
    </>
  );
};

const NotificationBarContainer = styled.div`
  margin-left: 1%;
  width: 92%;
  background-color: #d0e7ff;
  border-radius: 4px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;

  ${desktopMediaQuery} {
    max-width: 65vw;
    margin-left: 10vw;
  }
`;

const NotificationText = styled.div`
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const MoreInfoButton = styled.button`
  color: black;
  background: none;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  color: black;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
`;

export default NotificationBar;
