import styled from 'styled-components';
import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  PRIMARY_COLOR,
  PRIMARY_TEXT_COLOR,
} from '../../../constants';
import { CloseButtonInModal } from '../../common/CloseButtonInModal';
import { StyledButton } from '../../common/StyledButton';
import TransitioningModal from '../TransitioningModal';
import { useRef, useState } from 'react';
import { FaArrowDown } from 'react-icons/fa6';

interface I {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const NotificationModal = ({ showModal, setShowModal }: I) => {
  const handleClose = () => {
    setShowModal(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  return (
    <>
      <Modal
        isOpen={showModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          },
          content: {
            position: 'relative',
            backgroundColor: BACKGROUND_COLOR,
            padding: '30px',
            width: '78%',
            maxHeight: '80vh',
            maxWidth: '550px',
            margin: 'auto',
            marginTop: '1vh',
            paddingBottom: '40px',
          },
        }}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
        onRequestClose={handleClose}
      >
        <CloseButtonInModal
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            color: PRIMARY_TEXT_COLOR,
          }}
          onClick={handleClose}
        />

        {showMoreInfo ? (
          <MoreInfo />
        ) : (
          <ShorterContent handleClose={handleClose} />
        )}
      </Modal>
    </>
  );
};

/*
<OKButton onClick={handleClose}>OK ✅</OKButton>
const OKButton = styled(StyledButton)`
  border-color: ${PRIMARY_COLOR};
`;
*/

const ButtonsContainer = styled.div`
  display: flex;
  // on top of each other
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ShowMoreButton = styled(StyledButton)`
  border: 1px solid ${BORDER_COLOR};
  margin-top: 16px;
`;

const CTAButton = styled(ShowMoreButton)`
  border: 1px solid ${PRIMARY_COLOR};
`;

const Modal = styled(TransitioningModal)`
  display: flex;
  flex-direction: column;
`;

const ModalText = styled.div`
  font-size: 16px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: center;
  margin-bottom: 16px;

  text-align: left;
`;

const List = styled(ModalText)`
  margin-top: -8px;
`;

const TitleText = styled(ModalText)`
  font-size: 20px;
  margin-bottom: 36px;
  text-align: center;
`;

const ShorterContent = ({ handleClose }: { handleClose: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);
  const handleNavToFeed = () => {
    window.location.href = 'https://uncensoredai.io';
    handleClose();
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const handleScrollDown = () => {
    // scroll to as down as you can
    ref.current?.scrollTo({
      top: ref.current?.scrollHeight,
      behavior: 'smooth',
    });
  };
  return (
    <ScrollableDiv ref={ref}>
      <TitleText>Important Update: We're Moving!</TitleText>

      <List>
        <b>🌟 We're becoming UncensoredAI.io</b>
        <p>- Due to banking requirements, we're moving to a new domain</p>
        <p>- OnlyFakes.app will be discontinued soon</p>
        <p>- All features and more are available at UncensoredAI.io</p>
      </List>
      <br />

      <List>
        <b>✨ What this means for you</b>
        <p>- Your login credentials will work on the new site</p>
        <p>- All your images and data will be preserved</p>
        <p>- Same great features, plus exciting new updates coming soon</p>
      </List>
      <br />

      <ModalText>
        🔒 This change helps us provide a more reliable service while maintaining 
        all the features you love
      </ModalText>

      <br />
      <ModalText>
        ❓ Questions? Reach out on Discord, Reddit, or hello@uncensoredai.io
      </ModalText>

      <FloatingScrollDownButton onClick={handleScrollDown} />

      <ButtonsContainer>
        <CTAButton onClick={handleNavToFeed}>🔍 Visit UncensoredAI.io</CTAButton>
        <ShowMoreButton onClick={handleCloseModal}>
          Continue browsing
        </ShowMoreButton>
      </ButtonsContainer>
    </ScrollableDiv>
  );
};

const MoreInfo = () => (
  <ScrollableDiv>
    <TitleText>🎨 Styles (beta)</TitleText>

    <ModalText>
      Styles-feature makes it much easier to generate great images without
      adding 500 characters of "prompt magic".
    </ModalText>

    <br />
    <List>
      <b>Under the hood, it works by:</b>
      <p>✨ Adding hidden prompt before and after user prompt</p>
      <p>🚫 Throwing in a hidden negative prompt</p>
      <p>🔧 Changing the engine</p>
    </List>
    <br />

    <ModalText>
      I suggest trying out the default styles and NOT adding any extra prompts,
      such as (detailed face and eyes) or negative prompts, such as (deformed)
    </ModalText>

    <ModalText>
      Click the edit 🖌️ buttons in the More menu to learn how the styles are
      formed, and then create your own!
    </ModalText>

    <ModalText>
      Note: you can skip using styles by using the "None" style
    </ModalText>

    <br />

    <ModalText style={{ marginTop: '16px' }}>
      ⭐ But wait, there's more: I'm opening up all Gold engines for free!
    </ModalText>

    <ModalText>
      Please bear with me as I continue to improve the feature and fix bugs.
      Give me feedback through Discord, Reddit or Email. I just had a baby so I
      don't have energy to work too much atm, though :)
    </ModalText>
  </ScrollableDiv>
);

const ScrollableDiv = styled.div`
  overflow-y: auto;
  max-height: 80vh;
  padding-right: 16px;
  margin-top: 16px;
`;

const FloatingScrollDownButton = styled(FaArrowDown)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 24px;
  color: ${PRIMARY_COLOR};
  cursor: pointer;
`;

export default NotificationModal;
